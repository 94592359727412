// export default (store) => {
//     const ability = store.getters['auth/ability']
//
//     const permissions = store.state.auth.user && store.state.auth.user.roles
//     ? store.state.auth.user.roles.reduce((acc, item) => {
//         return acc.concat(item.permissions.map(item => item.name)); //Получаем массив разрешений
//     }, [])
//         : [] // Возвращаем пустой массив, если user или roles отсутствуют
//
//     ability.update([{ action: permissions, subject: 'all', conditions: { published: true, published2: false }}])
//
//     return store.subscribe((mutation) => {
//         switch (mutation.type) {
//             case 'auth/loginSuccess': {
//                 const get_permissions = mutation.payload.roles.reduce((acc, item) => {
//                     return acc.concat(item.permissions.map(item => item.name)); //Получаем массив разрешений
//                 }, [])
//                 ability.update([{ action: get_permissions, subject: 'all'}])
//                 console.log(ability)
//                 break
//             }
//             case 'auth/logout': {
//                 ability.update([{action: 'read', subject: 'all'}])
//                 break
//             }
//         }
//     })
// }


export default (store) => {
    const ability = store.getters['auth/ability'];

    // Функция для получения разрешений из ролей с условиями
    const getPermissions = (roles) => {
        return roles.reduce((acc, role) => {
            role.permissions.forEach(permission => {
                // Добавляем разрешение с условиями
                acc.push({
                    action: permission.name,
                    subject: 'all',
                    conditions: permission.conditions || [{}] // Используем пустой массив, если условий нет
                });
            });
            return acc;
        }, []);
    };

    const permissions = store.state.auth.user && store.state.auth.user.roles
        ? getPermissions(store.state.auth.user.roles)
        : [];

    ability.update(permissions);

    return store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'auth/loginSuccess': {
                const get_permissions = getPermissions(mutation.payload.roles);
                ability.update(get_permissions);
                console.log(ability);
                break;
            }
            case 'auth/logout': {
                ability.update([{ action: 'read', subject: 'all' }]);
                break;
            }
        }
    });
};



// ability.update([{ action: get_permissions, subject: 'all', conditions: { published: true } }])

// // функция для создания правил, воспользуемся когда нам потребуется
// export default  function defineAbilityFor(role) {
//     const {can, rules} = new AbilityBuilder();
//     const abilities_arr = role.roles.reduce((acc, item) => {
//         const ability_item = item.permissions
//         acc.push(ability_item)
//     },[])  //Получаем способности из API
//     console.log(abilities_arr)
//     can(abilities_arr, 'all');
//
//      return new Ability(rules);
//
// }
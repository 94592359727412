<template>
    <div class="text-right">
        <v-dialog
                v-model="dialog"
                persistent
                width="600"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        color="primary"
                        depressed
                        v-bind="attrs"
                        v-on="on"
                        v-if="$can('ORDER_CREATE', 'all')"
                >
                    Создать
                </v-btn>
            </template>

            <v-card>
                <v-card-title class="headline">
                    Новый договор
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-form
                        ref="form"
                        v-model="validForm"
                        lazy-validation
                >
                    <v-card-text>
                    <v-container>

                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="companySelect"
                                        :items="companiesItems"
                                        item-text="companyname"
                                        item-value="companyid"
                                        label="Организация"
                                        return-object
                                        :rules="requiredRules"
                                        required
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-text-field
                                        v-model="contractnumber"
                                        label="Номер"
                                        :rules="requiredRules"
                                        required
                                ></v-text-field>
                            </v-col>

                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                            >
<!--                                <v-menu-->
<!--                                        v-model="orderDateMenu"-->
<!--                                        :close-on-content-click="false"-->
<!--                                        :nudge-right="40"-->
<!--                                        transition="scale-transition"-->
<!--                                        offset-y-->
<!--                                        min-width="auto"-->
<!--                                >-->
<!--                                    <template v-slot:activator="{ on, attrs }">-->
<!--                                        <v-text-field-->
<!--                                                v-model="orderdata"-->
<!--                                                label="Дата*"-->
<!--                                                :rules="dateRules"-->
<!--                                                v-mask="'##.##.####'"-->
<!--                                                required-->
<!--                                                v-bind="attrs"-->
<!--                                                v-on="on"-->
<!--                                        ></v-text-field>-->
<!--                                    </template>-->
<!--                                    <v-date-picker-->
<!--                                            locale="ru"-->
<!--                                            :v-model="orderdata"-->
<!--                                            @input="orderDateMenu = false"-->
<!--                                    ></v-date-picker>-->


<!--                                </v-menu>-->

                                <vc-date-picker v-model="orderdata">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <v-text-field
                                                label="Дата"
                                                :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                v-mask="'##.##.####'"
                                                required
                                                v-on="inputEvents"
                                                :value="inputValue"
                                                readonly
                                        ></v-text-field>
                                    </template>
                                </vc-date-picker>

                            </v-col>
                            <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                            >
<!--                                <v-text-field-->
<!--                                        v-model="orderdeadline"-->
<!--                                        label="Срок*"-->
<!--                                        :rules="dateRules"-->
<!--                                        v-mask="'##.##.####'"-->
<!--                                        required-->
<!--                                ></v-text-field>-->
                                <vc-date-picker v-model="orderdeadline">
                                    <template v-slot="{ inputValue, inputEvents }">
                                        <v-text-field
                                                label="Срок"
                                                :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                v-mask="'##.##.####'"
                                                required
                                                v-on="inputEvents"
                                                :value="inputValue"
                                                readonly
                                                :hint="`${orderFullDays} дн.`"
                                                persistent-hint
                                        ></v-text-field>
                                    </template>
                                </vc-date-picker>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        placeholder="Введите не менее 3-х символов для поиска"
                                        clearable
                                        v-model="customerid"
                                        :items="customerItems"
                                        :search-input.sync="customerSearch"
                                        item-value="customerid"
                                        item-text="fullname"
                                        label="Заказчик"
                                        @click:clear="clearCustomerSearch"
                                        :rules="requiredRules"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        placeholder="Введите не менее 5-х символов для поиска"
                                        clearable
                                        v-model="estateid"
                                        :items="estateItems"
                                        :search-input.sync="estateSearch"
                                        item-value="estateid"
                                        item-text="fulladdress"
                                        label="Объект"
                                        @click:clear="clearEstateSearch"
                                        :rules="requiredRules"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                        <v-switch
                                v-model="isDeparture"
                                label="Выезд на объект"
                        ></v-switch>
                            </v-col>
                        </v-row>

                        <transition name="fade">
                            <v-row
                                v-if="isDeparture"
                            >
                                <v-col>
                                    <vc-date-picker v-model="datedeparture">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <v-text-field
                                                    label="Дата выезда"
                                                    v-mask="'##.##.####'"
                                                    required
                                                    v-on="inputEvents"
                                                    :value="inputValue"
                                                    readonly
                                                    :rules="requiredRules"
                                            ></v-text-field>
                                        </template>
                                    </vc-date-picker>
                                </v-col>
                                <v-col>
                                    <v-select
                                            v-model="timeDepartureSelect"
                                            :items="timeDeparture"
                                            label="Время выезда"
                                            item-text="label"
                                            item-value="value"
                                            return-object
                                            :rules="requiredRules"
                                            required
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </transition>

                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Руководитель проекта"
                                        :rules="requiredRules"
                                        v-model="managerid"
                                        :items="employeeItems"
                                        item-value="employeesid"
                                        item-text="fullname"
                                        clearable
                                >
                                    <template v-slot:item="data">
<!--                                        &lt;!&ndash;                <v-list-item-content v-text="data.item.servicename"></v-list-item-content>&ndash;&gt;-->
<!--                                        <v-list-item-content>-->
<!--                                            <v-list-item-title >{{ data.item.servicename }} <span style="color: #1f98ad;">[{{data.item.activiti}}]</span></v-list-item-title>-->
<!--                                            <v-list-item-subtitle v-html="data.item.servicegroupkod.servicegroupname"></v-list-item-subtitle>-->
<!--                                        </v-list-item-content>-->
                                        <v-list-item-avatar>
                                            <img :src="`${avatars}${data.item.avatar}`">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.post"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>

                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                        label="Команда проекта"
                                        v-model="teamIds"
                                        :items="employeeItems"
                                        item-value="employeesid"
                                        item-text="fullname"
                                        chips
                                        deletable-chips
                                        multiple
                                >
                                    <template v-slot:item="data">
                                        <v-list-item-avatar>
                                            <img :src="`${avatars}${data.item.avatar}`">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.fullname"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.post"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>

                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="lkSelect"
                                        :items="lkAllowDownload"
                                        item-text="label"
                                        item-value="value"
                                        label="Доступ к файлам ЛК"
                                        return-object
                                        :rules="requiredRules"
                                        required
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-select
                                        v-model="sourceSelect"
                                        :items="sourceItems"
                                        item-text="answer"
                                        item-value="id"
                                        label="Источник информации об организации"
                                        return-object
                                        :rules="requiredRules"
                                        required
                                ></v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-textarea
                                        v-model="ordertarget"
                                        label="Краткое описание предмета договора"
                                        rows="2"
                                ></v-textarea>
                            </v-col>
                        </v-row>


                        <v-row>
                            <v-col>
                                <v-btn
                                        class="mr-4 mt-4"
                                        color="success"
                                        :loading="loading"
                                        @click="submitForm"
                                >
                                    Создать
                                </v-btn>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-card-text>
                </v-form>

            </v-card>



        </v-dialog>
    </div>
</template>

<script>
    import CustomerDataService from "@/services/CustomerDataService";
    import RealestateDataService from "@/services/RealestateDataService";
    import CompanyDataService from "@/services/CompanyDataService";
    import SourceDataService from "@/services/SourceDataService";
    import OrderDataService from "@/services/OrderDataService";
    import EmployeeDataService from "@/services/EmployeeDataService";
    import NoticeDataService from "@/services/NoticeDataService";
    import * as ExternalRules from "@/rules/ExternalRules";


    let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timezone: 'UTC'
    };

    export default {
        name: "AddContractForm",
        data () {
            return {
                publicPath: process.env.BASE_URL,
                dialog: false,
                validForm: true,
                contractnumber: null,
                customerid: null,
                estateid: null,
                teamIds: [], //массив Id сотрудников - команды проекта
                managerid: null, //id руководителя проекта
                // orderdata: new Date().toLocaleString("ru", options),
                orderdata: new Date(),
                //orderdeadline: Date.now() + 604800000, // +7 дней
                orderdeadline: null,
                ordertarget: null,

                customerItems: [],
                employeeItems: [],
                estateItems: [],
                customerSearch: null,
                estateSearch: null,
                externalRules: ExternalRules,

                lkSelect: null,
                lkAllowDownload: [
                    { label: 'Без оплаты', value: '0' },
                    { label: 'Оплата 30% и более', value: '30' },
                    { label: 'Оплата 50% и более', value: '50' },
                    { label: 'Оплата 100%', value: '100' }
                ],
                datedeparture: null,
                timeDepartureSelect: null,
                timeDeparture: [
                    { label: '8:00', value: '8:00' },
                    { label: '9:00', value: '9:00' },
                    { label: '10:00', value: '10:00' },
                    { label: '11:00', value: '11:00' },
                    { label: '12:00', value: '12:00' },
                    { label: '13:00', value: '13:00' },
                    { label: '14:00', value: '14:00' },
                    { label: '15:00', value: '15:00' },
                    { label: '16:00', value: '16:00' },
                    { label: '17:00', value: '17:00' },
                    { label: '18:00', value: '18:00' },
                    { label: '19:00', value: '19:00' }
                ],

                companiesItems: [],
                companySelect: null,
                sourceItems: [],
                sourceSelect: null,

                isDeparture: false,

                orderDateMenu: false,

                requiredRules: [
                    v => !!v || 'Пожалуйста, заполните это поле',
                ],
                dateRules: [
                    v => (!v || /^(((0[1-9]|[12]\d|3[01])\.(0[13578]|1[02])\.((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\.(0[13456789]|1[012])\.((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\.02\.((19|[2-9]\d)\d{2}))|(29\.02\.((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/.test(v)) || 'Пожалуйста, введите дату',
                ],
                loading: false,
                avatars: process.env.VUE_APP_AVATARS

            }
        },

        watch: {
            customerSearch() {
                if (this.customerItems.length > 0) return

                if (this.customerSearch.length > 3) {
                    CustomerDataService.findBySurname(this.customerSearch)
                        .then(response => {
                            this.customerItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
            estateSearch() {
                if (this.estateItems.length > 0) return

                if (this.estateSearch.length > 5) {
                    RealestateDataService.find(this.estateSearch)
                        .then(response => {
                            this.estateItems = response.data
                            console.log(response.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            }
        },
        computed: {
            orderFullDays() {
                if (this.orderdeadline) {
                    return Math.round((this.orderdeadline - this.orderdata) / 86400000) //Получаем срок в днях
                } else {
                    return 0
                }
            }
        },
        methods: {
            submitForm() {
                if (this.$refs.form.validate()) {
                    this.loading = true
                    let data = {
                        customerkod: this.customerid,
                        estatecod: this.estateid,
                        managerid: this.managerid,
                        team: this.teamIds,//Массив - комманда проекта
                        creatorid: this.$store.state.auth.user.userid,
                        contractnumber: this.contractnumber,
                        orderdata: this.orderdata.toISOString().substr(0, 10),
                        orderdeadline: new Date(this.orderdeadline).toISOString().substr(0, 10),
                        orderorganization: this.companySelect.companyid,
                        orderdivision: 'V',
                        marketingsourcecode: this.sourceSelect.id,
                        orderstatus: '4', //Новый
                        departureflag: Number(this.isDeparture),
                        ordertarget: this.ordertarget,
                        allowdownloadwhenpaying: this.lkSelect.value
                    }


                    if (this.isDeparture) {
                        data['datedeparture'] = this.datedeparture.toISOString().substr(0, 10)
                        data['timedeparture'] = this.timeDepartureSelect.value
                    }

                    OrderDataService.create(data)
                        .then(response => {
                            NoticeDataService.create({creatorid: null, employeesid: this.managerid, noticetext: `Вы назначены руководителем проекта по объекту: ${this.estateSearch}.<br/><a href="/contracts/${response.data.id}"> Договор №${this.contractnumber} от ${this.orderdata.toLocaleString("ru", options)}</a>`});//Создаем уведомление руководителю проекта
                            //Если выбраны сотрудники в команду проекта, создаем каждому из них уведомление
                            if (this.teamIds.length > 0) {
                                this.teamIds.forEach(item => {
                                    NoticeDataService.create({creatorid: null, employeesid: item, noticetext: `Вы добавлены в команду проекта по объекту: ${this.estateSearch}.<br/><a href="/contracts/${response.data.id}"> Договор №${this.contractnumber} от ${this.orderdata.toLocaleString("ru", options)}</a>`});//Создаем уведомление члену команды
                                })
                            }
                            this.$router.push({ name: 'EditContract', params: { id : response.data.id }})
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            },
            clearCustomerSearch() {
                this.customerItems = []
            },
            clearEstateSearch() {
                this.estateItems = []
            },
            //Огранизации (ООО АртГеоКом, ИП ...)
            getCompany () {
                CompanyDataService.get()
                    .then(response => {
                            this.companiesItems = response.data
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Источник информации об организации
            getSource () {
                SourceDataService.get()
                    .then(response => {
                        this.sourceItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            //Руководитель проекта
            getManagers () {
                EmployeeDataService.find()
                    .then(response => {
                        this.employeeItems = response.data
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        },
        mounted () {
            this.getCompany()
            this.getSource()
            this.getManagers()
        }
    }
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }

</style>
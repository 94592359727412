<template>
<div>
    <v-dialog
            v-model="showDialog"
            width="250"
    >
        <v-card>
            <v-card-title class="grey lighten-2">
                Выберите результат
            </v-card-title>
            <v-card-text style="padding-top: 20px" class="d-flex justify-space-between">


                        <v-btn
                                color="success"
                                depressed
                                small
                                @click="setStatus(1)"
                        >
                            Выполнен
                        </v-btn>


                        <v-btn
                                color="error"
                                depressed
                                small
                                @click="setStatus(3)"
                        >
                            Отказ
                        </v-btn>



            </v-card-text>
        </v-card>
    </v-dialog>



    <div style="margin: 15px 15px;" v-if="statusid == 4">
        <table  class="order-stepper">
            <tbody>
            <tr>
                <td v-tooltip="'Новый'" @click="setStatus(4)" style="background-color: #44ddbf; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="`В работе`" @click="setStatus(0)" style=" border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="'Завершить'" @click="showDialog = true" style=" border-bottom-left-radius: 0px !important;"></td>
            </tr>
            </tbody>
        </table>
        <div class="step-name" >Новый</div>
    </div>

    <div style="margin: 15px 15px;" v-if="statusid == 0">
        <table class="order-stepper">
            <tbody>
            <tr>
                <td v-tooltip="'Новый'" @click="setStatus(4)" style="background-color: #479de9; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="`В работе`" @click="setStatus(0)" style=" background-color: #479de9; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="'Завершить'" @click="showDialog = true" style="border-bottom-left-radius: 0px !important;"></td>
            </tr>
            </tbody>
        </table>
        <div class="step-name" >В работе</div>
    </div>

    <div style="margin: 15px 15px;" v-if="statusid == 1">
        <table class="order-stepper">
            <tbody>
            <tr>
                <td v-tooltip="'Новый'" @click="setStatus(4)" style="background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="`В работе`" @click="setStatus(0)" style=" background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="'Завершить'" @click="showDialog = true" style=" background-color: #65b168; border-bottom-left-radius: 0px !important;"></td>
            </tr>
            </tbody>
        </table>
        <div class="step-name" >Выполнен</div>
    </div>

    <div style="margin: 15px 15px;" v-if="statusid == 3">
        <table class="order-stepper">
            <tbody>
            <tr>
                <td v-tooltip="'Новый'" @click="setStatus(4)"  style=" background-color: #e95d5a; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="`В работе`" @click="setStatus(0)" style="background-color: #e95d5a; border-bottom-left-radius: 0px !important;"></td>
                <td v-tooltip="'Завершить'" @click="showDialog = true" style="background-color: #e95d5a; border-bottom-left-radius: 0px !important;"></td>
            </tr>
            </tbody>
        </table>
        <div class="step-name">Отказ</div>
    </div>

<!--        <div style="margin: 15px 15px;">-->
<!--            <table style="border-spacing: 0; height: 9px; width: 100%; border: 1px solid #ccc; border-collapse: collapse;min-width: 130px;max-width: 350px;">-->
<!--                <tbody>-->
<!--                <tr>-->
<!--                    <td-->
<!--                            style="border: 1px solid #ccc;"-->
<!--                            v-for="(item, index) in status" :key="item.code"-->
<!--                            @click="setStatus(item.code)"-->
<!--                    >-->
<!--                        <span-->
<!--                        v-show="getStatusIndex >= index"-->
<!--                        >-->
<!--                            1-->
<!--                        </span>-->
<!--                    </td>-->
<!--                </tr>-->
<!--                </tbody>-->
<!--            </table>-->
<!--            <div style="color: rgb(140, 143, 145); font-size: 11px; padding: 5px 0 0 0px; line-height: 14px !important; font-weight: 300;" >-->
<!--                {{ getStatusName }}-->
<!--            </div>-->
<!--        </div>-->





</div>
</template>

<script>
    import OrderDataService from "@/services/OrderDataService";

    export default {
        props: ['orderstatus', 'orderid'],
        name: "OrderStepper",
        data () {
            return {
                statusid: this.orderstatus,
                showDialog: false,
                // status: [
                //     {code: 4, color: '#44ddbf', name: 'Новый'},
                //     {code: 0, color: '#479de9', name: 'В работе'},
                //     {code: 1, color: '#65b168', name: 'Выполнен'},
                //     {code: 3, color: '#e95d5a', name: 'Отказ'}
                // ],
            }
        },
        computed: {
            // getStatusName() {
            //     let statusName = this.status.find( item => item.code == Number(this.statusid))
            //     return statusName.name
            // },
            // getStatusIndex() {
            //     let statusIndex = this.status.findIndex( item => item.code == Number(this.statusid))
            //     return statusIndex
            // }

            getPermissions() {
                return this.$store.getters['auth/ability']
            }

        },
        watch: {
            //Использую слежение за входным параметром. Без этого был глюк, показывал не правильные статусы
            orderstatus: function (val) {
                this.statusid = val
            }
        },
        methods: {
            //Установка нового статуса
            setStatus(code) {
                if (this.getPermissions.can('ORDER_STATUS_UPDATE', 'all')) {
                    OrderDataService.setOrderStatus(this.orderid, code)
                        .then(response => {
                            this.statusid = code
                            console.log(response.data)
                            this.showDialog = false //Если открыт диалог, закрываем
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }
            },
        }

    }
</script>

<style scoped>
    .order-stepper {
        border-spacing: 0;
        height: 9px;
        width: 100%;
        border: 1px solid #ccc;
        border-collapse: collapse;
        min-width: 130px;
        max-width: 350px;
    }

    .order-stepper td {
        border: 1px solid #ccc;
    }

    .order-stepper td:hover {
        border: 1px solid #bdb9b9;
        cursor: pointer;
    }

    .step-name {
        color: rgb(140, 143, 145);
        font-size: 11px;
        padding: 5px 0 0 0px;
        line-height: 14px !important;
        font-weight: 300;
    }

</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Customers from '../views/Customers.vue'
import EditCustomer from '../views/EditCustomer'
import EditDocument from "@/views/EditDocument";
import Contracts from '../views/Contracts'
import Dashboard from "@/views/Dashboard";
import Tasks from "@/views/Tasks";
import Employees from "@/views/Employees";
import Calendar from "@/views/Calendar";
import Gis from "@/views/Gis";
import Login from "@/views/Login";
import Documents from "@/views/Documents";
import Correspondence from "@/views/Correspondence";
import Salary from "@/views/Salary";
import EditRealestates from "@/views/EditRealestates";
import EditContract from "@/views/EditContract";
import EditCorrespondence from "@/views/EditCorrespondence";
import Profitandloss from "@/views/Profitandloss";
import Settings from "@/views/Settings";
import store from "@/store";
import Acts from "@/views/Acts";
import Offers from "@/views/Offers";
import EditOffers from "@/views/EditOffers";
import Reports from "@/views/Reports";
import EditTask from "@/components/EditTask";
import Marketing from "@/views/Marketing";
import Accounts from "@/views/Accounts";
import EditAccount from "@/components/EditAccount";
import GoogleDocs from "@/views/GoogleDocs";
import EditEmployee from "@/components/EditEmployee";
import ContractsSum from "@/components/reports/ContractsSum";
import WorksReport from "@/components/reports/WorksReport";
import Dialog from "@/components/elements/Dialog";
import Roles from "@/views/Roles";
import EditRole from "@/views/EditRole";
import IGI from "@/views/IGI"


Vue.use(VueRouter)

//Читаем пермишены из стора
const ability = store.getters['auth/ability']

const routes = [
  {
    path: '/',
    redirect: { name: 'Dashboard' }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks
  },
  {
    path: '/tasks/:id',
    name: 'EditTask',
    component: EditTask
  },
  {
    path: '/employees',
    name: 'Employees',
    component: Employees
  },
  {
    path: '/employees/:id',
    name: 'EditEmployee',
    component: EditEmployee
  },
  {
    path: '/roles',
    name: 'Roles',
    component: Roles,
    beforeEnter: (to, from, next) => {
      if (ability.cannot('ROLES_LIST_READ', 'all')) {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/roles/:id',
    name: 'EditRole',
    component: EditRole
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers,
  },
  {
    path: '/customers/:id',
    name: 'EditCustomer',
    component: EditCustomer,
    props: true //Разрешаем передавить входные параметры в компонент. Значения route.params будут устанавливаться входными параметрами компонента. https://v3.router.vuejs.org/ru/guide/essentials/passing-props.html
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    beforeEnter: (to, from, next) => {
      if (ability.cannot('ACCOUNTS_LIST_READ', 'all')) {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/accounts/:id',
    name: 'EditAccount',
    component: EditAccount
  },
  {
    path: '/contracts',
    name: 'Contracts',
    component: Contracts
  },
  {
    path: '/contracts/:id',
    name: 'EditContract',
    component: EditContract
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/gis',
    name: 'Gis',
    component: Gis
  },
  {
    path: '/search-kamaz',
    name: 'SearchKamaz',
    beforeEnter() { window.open('https://plus.auto-scan.ru/api/mapframe?public_api_key=d12ac89cf96467f7c42ae5812aec05ced964a1a2')}
  },
  {
    path: '/rosreestr',
    name: 'Rosreestr',
    beforeEnter() { window.open('https://pkk.rosreestr.ru/#/search/51.654054920947395,39.36176656446731/11/@d98n7zxh')}
  },
  {
    path: '/ymaps',
    name: 'Ymaps',
    beforeEnter() { window.open('https://yandex.ru/maps/193/voronezh/?ll=39.200269%2C51.660781&z=12')}
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents,
    beforeEnter: (to, from, next) => {
      if (ability.cannot('DOCUMENTS_LIST_READ', 'all')) {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/acts',
    name: 'Acts',
    component: Acts,
    beforeEnter: (to, from, next) => {
      if (ability.cannot('ACTS_LIST_READ', 'all')) {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/documents/:id',
    name: 'EditDocument',
    component: EditDocument,
    beforeEnter: (to, from, next) => {
      if (ability.cannot('DOCUMENT_READ', 'all')) {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers,
    beforeEnter: (to, from, next) => {
      if (ability.cannot('OFFERS_LIST_READ', 'all')) {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    beforeEnter: (to, from, next) => {
      if (ability.cannot('REPORTS_LIST_READ', 'all')) {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },

  {
    path: '/reports/contracts',
    name: 'ContractsSum',
    component: ContractsSum,
    beforeEnter: (to, from, next) => {
      if  (store.state.auth.user.roles[0] != 'ROLE_SUPER_ADMIN') {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },

  {
    path: '/reports/pnl',
    name: 'Profitandloss',
    component: Profitandloss,
    beforeEnter: (to, from, next) => {
      if  (store.state.auth.user.roles[0] != 'ROLE_SUPER_ADMIN') {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },

  {
    path: '/reports/works',
    name: 'WorksReport',
    component: WorksReport
  },

  {
    path: '/marketing',
    name: 'Marketing',
    component: Marketing,
    beforeEnter: (to, from, next) => {
      if  (store.state.auth.user.roles[0] != 'ROLE_SUPER_ADMIN') {
        alert('Доступ к ресурсу запрещен!');
        next(false)
      } else {
        next()
      }
    }
  },

  {
    path: '/offers/:id',
    name: 'EditOffer',
    component: EditOffers,
  },

  {
    path: '/correspondence',
    name: 'Correspondence',
    component: Correspondence,
      // meta: {
      //     roles: ['developer', 'ceo', 'moderator']
      // }
  },
  {
    path: '/correspondence/:id',
    name: 'EditCorrespondence',
    component: EditCorrespondence
  },
  {
    path: '/salary',
    name: 'Salary',
    component: Salary
  },
    //Отображение гугл документа (редактирование)
  {
    path: '/googledocs/:id',
    name: 'Googledocs',
    component: GoogleDocs
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/realestates',
    name: 'Realestates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Realestates.vue')
  },
  {
    path: '/realestates/:id',
    name: 'EditRealestates',
    component: EditRealestates,
  },

  {
    path: '/testdialog',
    name: 'TestDialog',
    component: Dialog,
    beforeEnter: (to, from, next) => {
      store.dispatch('showDialog');
      next();
    }
  },

  {
    path: '/igi',
    name: 'IGI',
    component: IGI,
  },
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//check Authorized status everytime a navigating action is trigger
//https://www.bezkoder.com/jwt-vue-vuex-authentication/
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }




});

export default router

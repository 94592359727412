<template>
  <div>
    <RolesTable/>
  </div>
</template>

<script>
import RolesTable from "@/components/RolesTable";

export default {
  name: 'Roles',
  components: {
    RolesTable
  },


}
</script>

<template>
    <div>
        <!--Сообщение об успешном Сохранении данных-->
        <v-snackbar
                v-model="snackbarSave"
                color="green"
                top
                :timeout="1000"
        >
            Данные сохранены
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarSave = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <!--End Сообщение об успешном Сохранении данных-->

        <!--Сообщение об успешном Смене пароля-->
        <v-snackbar
                v-model="snackbarChangePassword"
                color="green"
                top
                :timeout="3000"
        >
            Пароль успешно изменен
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarChangePassword = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <!--End Сообщение об успешном Смене пароля-->

        <!--Сообщение об ошибке Смены пароля-->
        <v-snackbar
                v-model="snackbarChangePasswordError"
                color="red"
                top
                :timeout="5000"
        >
            Старый пароль введен не верно. Пароль не был изменен
            <template v-slot:action="{ attrs }">
                <v-btn
                        color="grey lighten-2"
                        icon
                        v-bind="attrs"
                        @click="snackbarChangePasswordError = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <!--End Сообщение об ошибке Смены пароля-->




        <!--      Диалоговое окно смены аватара-->
        <v-dialog
                max-width="500"
                persistent
                v-model="changeAvatarDialog"
        >
            <v-card>
                <v-card-title class="headline">
                    Изменить аватар
                    <v-spacer></v-spacer>
                    <v-btn
                            icon
                            @click="closeChangeAvatarDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col>
                                <cropper
                                        :src="image.src"
                                        ref="cropper"
                                        class="cropper"
                                        :stencil-props="{
                                            previewClass: 'preview'
                                            }"
                                        stencil-component="circle-stencil"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeChangeAvatarDialog"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="saveAvatar"
                    >
                        Сохранить
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-row>
            <v-col cols="auto">
                <h1 class="data-name" v-show="!isEditFullName">
                    {{ employee.surname }} {{ employee.name }} {{ employee.secondname }}
                    <v-icon
                            @click="editFullName"
                            right
                    >
                        mdi-pencil
                    </v-icon>
                </h1>
                <div v-show="isEditFullName">
                    <v-form
                            v-model="valid"
                            lazy-validation
                    >
                        <v-text-field
                                label="Фамилия"
                                v-model="editedItem.surname"
                                :rules="externalRules.requiredRules"
                                ref="surname"
                        ></v-text-field>
                        <v-text-field
                                label="Имя"
                                v-model="editedItem.name"
                                :rules="externalRules.requiredRules"
                        ></v-text-field>
                        <v-text-field
                                label="Отчество"
                                v-model="editedItem.secondname"
                                :rules="externalRules.requiredRules"
                        ></v-text-field>
                    </v-form>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="closeFullName"
                    >
                        Отмена
                    </v-btn>
                    <v-btn
                            :disabled="!valid"
                            color="blue darken-1"
                            text
                            @click="saveFullName"
                    >
                        Сохранить
                    </v-btn>
                </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
                    cols="auto"
            >
                <v-switch
                        label="Активен"
                        v-model="employee.enabled"
                        @change="updateEmployee"
                ></v-switch>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card
                    outlined
                >
                    <v-card-title>Основное</v-card-title>
                    <v-card-text>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <v-avatar
                                        size="60"
                                        class="float-left"
                                        @click="$refs.avatar_file.click()"
                                        @mouseover="hover = true"
                                        @mouseleave="hover = false"
                                        style="background-color: #cacaca"
                                >
                                    <input ref="avatar_file" type="file" class="d-none" accept="image/jpeg, image/png" @change="loadImage($event)">
                                    <v-icon
                                        size="30"
                                        v-if="hover"
                                    >
                                        mdi-image-search-outline
                                    </v-icon
                                    >
                                    <img
                                            :src="`${avatars}${employee.avatar}`"
                                            v-if="!hover"
                                    >
                                </v-avatar>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Должность
                                </div>
                                <div class="data-text" v-show="!isEditPost">
                                    <span v-if="employee.post">{{ employee.post }}</span>
                                    <span class="placeholder" v-else>Введите текст</span>
                                    <v-icon
                                            @click="editPost"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditPost">
                                    <v-form
                                            v-model="valid"
                                            lazy-validation
                                    >
                                        <v-text-field
                                                v-model="editedItem.post"
                                                :rules="externalRules.requiredRules"
                                                ref="post"
                                        ></v-text-field>
                                    </v-form>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closePost"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            color="blue darken-1"
                                            text
                                            @click="savePost"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Телефон
                                </div>
                                <div class="data-text" v-show="!isEditPhone">
                                    <span>{{ employee.phone }}</span>
                                    <v-icon
                                            @click="editPhone"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditPhone">
                                    <v-form
                                            v-model="valid"
                                            lazy-validation
                                    >
                                        <v-text-field
                                                v-mask="'+7 (###) ###-##-##'"
                                                v-model="editedItem.phone"
                                                :rules="externalRules.phoneRules.concat(externalRules.requiredRules)"
                                                ref="phone"
                                        ></v-text-field>
                                    </v-form>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closePhone"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            color="blue darken-1"
                                            text
                                            @click="savePhone"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    E-mail
                                </div>
                                <div class="data-text" v-show="!isEditEmail">
                                    <span>{{ employee.email }} </span>
                                    <v-icon
                                            @click="editEmail"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditEmail">
                                    <v-form
                                            v-model="valid"
                                            lazy-validation
                                    >
                                        <v-text-field
                                                v-model="editedItem.email"
                                                :rules="externalRules.emailRules.concat(externalRules.requiredRules)"
                                                ref="email"
                                        ></v-text-field>
                                    </v-form>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeEmail"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            color="blue darken-1"
                                            text
                                            @click="saveEmail"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Дата рождения
                                </div>
                                <div class="data-text" v-show="!isEditBirthday">
                                    <span>{{ DateFunction.DateIsoToRUS(employee.birthday.date) }}</span>
                                    <v-icon
                                            @click="editBirthday"
                                            right
                                            small
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </div>
                                <div v-show="isEditBirthday">
                                    <v-form
                                            v-model="valid"
                                            lazy-validation
                                    >
                                        <vc-date-picker v-model="editedItem.birthday" :model-config="modelConfig">
                                            <template v-slot="{ inputValue, togglePopover }">
                                                <v-text-field
                                                        style="width: 200px;"
                                                        :value="inputValue"
                                                        :rules="externalRules.dateRules.concat(externalRules.requiredRules)"
                                                        @click="togglePopover()"
                                                        v-mask="'##.##.####'"
                                                        clearable
                                                        v-model="editedItem.birthday"
                                                        ref="birthday"
                                                />
                                            </template>
                                        </vc-date-picker>
                                    </v-form>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeBirthday"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid"
                                            color="blue darken-1"
                                            text
                                            @click="saveBirthday"
                                    >
                                        Сохранить
                                    </v-btn>
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Имя пользователя
                                </div>
                                <div class="data-text">
                                    {{ employee.username }}
                                </div>
                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <v-btn
                                    small
                                    depressed
                                    color="primary"
                                    @click="changePassword"
                                    v-show="!isChangePassword"
                                    v-if="$route.params.id == $store.state.auth.user.userid || this.$store.state.auth.user.roles[0] ===  'ROLE_SUPER_ADMIN'"
                                >
                                    Изменить пароль
                                </v-btn>

                                <div v-show="isChangePassword">
                                    <v-form
                                            v-model="valid"
                                            lazy-validation
                                            ref="changepasswordform"
                                    >
                                        <v-text-field
                                                label="Старый пароль"
                                                v-model="oldPassword"
                                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show1 ? 'text' : 'password'"
                                                @click:append="show1 = !show1"
                                                :rules="externalRules.requiredRules"
                                        ></v-text-field>
                                        <v-text-field
                                                label="Новый пароль"
                                                v-model="newPassword"
                                                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show2 ? 'text' : 'password'"
                                                counter
                                                @click:append="show2 = !show2"
                                                :rules="externalRules.passwordRules.concat(externalRules.requiredRules)"
                                        ></v-text-field>
                                        <v-text-field
                                                label="Подтверждение пароля"
                                                v-model="newPasswordConfirm"
                                                :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show3 ? 'text' : 'password'"
                                                @click:append="show3 = !show3"
                                                :rules="externalRules.requiredRules.concat(passwordConfirmationRule)"
                                        ></v-text-field>
                                    </v-form>
                                    <v-btn
                                            color="blue darken-1"
                                            text
                                            @click="closeChangePassword"
                                    >
                                        Отмена
                                    </v-btn>
                                    <v-btn
                                            :disabled="!valid || oldPassword.length <= 0 || newPassword.length <= 0 || newPasswordConfirm.length <= 0"
                                            color="blue darken-1"
                                            text
                                            @click="saveNewPassword"
                                    >
                                        Изменить пароль
                                    </v-btn>
                                </div>

                            </div>
                        </v-row>
                        <v-row class="mx-0">
                            <div class="data-row">
                                <div class="data-label">
                                    Роли
                                </div>
                                <div class="data-text">
                                    <ul>
                                        <li v-for="item in employee.roles" :key="item.id">{{ item.name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import EmployeeDataService from "@/services/EmployeeDataService";
    import * as ExternalRules from "@/rules/ExternalRules";
    import DateFunction from "@/services/library/DateFunction";
    import { Cropper } from 'vue-advanced-cropper';
    import 'vue-advanced-cropper/dist/style.css';
    export default {
        name: "EditEmployee",
        components: {
            Cropper,
        },
        data() {
            return {
                // img: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
                image: {
                    src: null,
                    type: null
                },

                //Формат даты для календаря
                modelConfig: {
                    type: 'string',
                    mask: 'DD.MM.YYYY',
                },
                hover: false,
                externalRules: ExternalRules,
                employee: {}, //Объект сотрудника
                avatars: process.env.VUE_APP_AVATARS,
                editedItem: {}, //В этой переменной сохраняем объект при редактировании
                valid: true,

                isEditFullName: false,
                isEditPost: false,
                isEditPhone: false,
                isEditEmail: false,
                isEditBirthday: false,
                isChangePassword: false,

                show1: false,
                show2: false,
                show3: false,

                oldPassword: '',
                newPassword: '',
                newPasswordConfirm: '',




                snackbarSave: false,
                snackbarChangePassword: false,
                snackbarChangePasswordError: false,
                changeAvatarDialog: false,

                // isEditDeadline: false,
                // isEditSum: false,
                // isEditDescription: false,
                DateFunction: DateFunction
            }
        },
        computed: {
            passwordConfirmationRule() {
                return () =>
                    this.newPassword === this.newPasswordConfirm || "Пароли должны совпадать";
            }
        },

        methods: {
            //Загрузка изображения https://advanced-cropper.github.io/vue-advanced-cropper/guides/recipes.html#load-image-from-a-disc
            loadImage(event) {
                // Reference to the DOM input element
                const { files } = event.target;
                // Ensure that you have a file before attempting to read it
                if (files && files[0]) {
                    // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                    if (this.image.src) {
                        URL.revokeObjectURL(this.image.src)
                    }
                    // 2. Create the blob link to the file to optimize performance:
                    const blob = URL.createObjectURL(files[0]);

                    // 3. The steps below are designated to determine a file mime type to use it during the
                    // getting of a cropped image from the canvas. You can replace it them by the following string,
                    // but the type will be derived from the extension and it can lead to an incorrect result:
                    //
                    // this.image = {
                    //    src: blob;
                    //    type: files[0].type
                    // }

                    // Create a new FileReader to read this image binary data
                    const reader = new FileReader();
                    // Define a callback function to run, when FileReader finishes its job
                    reader.onload = (e) => {
                        // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                        this.image = {
                            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                            src: blob,
                            // Determine the image type to preserve it during the extracting the image from canvas:
                            type: "image/jpeg"//getMimeType(e.target.result, files[0].type),
                        };
                        console.log(e)
                    };
                    // Start the reader job - read file as a data url (base64 format)
                    reader.readAsArrayBuffer(files[0]);

                    //Отображаем диалог изменения аватара
                    this.changeAvatarDialog = true
                }
            },

            saveAvatar() {
                const { canvas} = this.$refs.cropper.getResult();
                if (canvas) {
                    const formData = new FormData();
                    canvas.toBlob(blob => {
                        formData.append('file', blob);
                        EmployeeDataService.changeAvatar(formData, { employeeid : this.$route.params.id })
                            .then(response => {
                                //this.employee = response.data
                                console.log(response.data);
                                this.changeAvatarDialog = false
                                this.employee.avatar = response.data.avatarfilename //Обновляем имя файла в аватаре
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    }, 'image/jpeg');
                }

            },

            getEmployee(id) {
                EmployeeDataService.get(id)
                    .then(response => {
                        this.employee = response.data
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },

            closeChangeAvatarDialog() {
                this.changeAvatarDialog = false
            },

            editFullName() {
                this.isEditFullName = true
                this.editedItem.surname = this.employee.surname
                this.editedItem.name = this.employee.name
                this.editedItem.secondname = this.employee.secondname
                this.$nextTick(() => {
                    this.$refs.surname.focus();
                })
            },
            closeFullName() {
                this.isEditFullName = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveFullName() {
                this.employee.surname = this.editedItem.surname
                this.employee.name = this.editedItem.name
                this.employee.secondname = this.editedItem.secondname
                this.closeFullName()
                this.updateEmployee()
            },

            editPost() {
                this.editedItem.post = this.employee.post
                this.isEditPost = true
                this.$nextTick(() => {
                    this.$refs.post.focus();
                })
            },
            closePost() {
                this.isEditPost = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            savePost() {
                this.employee.post = this.editedItem.post
                this.closePost()
                this.updateEmployee()
            },

            editPhone() {
                this.editedItem.phone = this.employee.phone
                this.isEditPhone= true
                this.$nextTick(() => {
                    this.$refs.phone.focus();
                })
            },
            closePhone() {
                this.isEditPhone = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            savePhone() {
                this.employee.phone = this.editedItem.phone
                this.closePhone()
                this.updateEmployee()
            },

            editEmail() {
                this.editedItem.email = this.employee.email
                this.isEditEmail = true
                this.$nextTick(() => {
                    this.$refs.email.focus();
                })
            },
            closeEmail() {
                this.isEditEmail = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveEmail() {
                this.employee.email = this.editedItem.email
                this.closeEmail()
                this.updateEmployee()
            },

            editBirthday() {
                this.isEditBirthday = true
                this.editedItem.birthday = DateFunction.DateIsoToRUS(this.employee.birthday.date)
                this.$nextTick(() => {
                    this.$refs.birthday.focus();
                })
            },

            changePassword() {
                this.isChangePassword = true
                //this.$refs.changepasswordform.validate()
            },
            closeChangePassword() {
                this.isChangePassword = false
                this.oldPassword = ''
                this.newPassword = ''
                this.newPasswordConfirm = ''
                this.$refs.changepasswordform.resetValidation();
            },
            saveNewPassword() {
                EmployeeDataService.changePassword(this.$route.params.id, this.oldPassword, this.newPassword)
                    .then(response => {
                        this.snackbarChangePassword = true
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                        this.snackbarChangePasswordError = true
                    })
                this.closeChangePassword()
            },

            closeBirthday() {
                this.isEditBirthday = false
                this.$nextTick(() => {
                    this.editedItem = {}
                })
            },
            saveBirthday() {
                this.employee.birthday.date = DateFunction.DateRUSToIso(this.editedItem.birthday)
                this.closeBirthday()
                this.updateEmployee()
            },




            updateEmployee() {
                let data = {
                    surname: this.employee.surname,
                    name: this.employee.name,
                    secondname: this.employee.secondname,
                    post: this.employee.post,
                    phone: this.employee.phone,
                    email: this.employee.email,
                    birthday: this.employee.birthday.date,
                    enabled: this.employee.enabled,
                }
                EmployeeDataService.update(this.employee.employeesid, data)
                    .then(response => {
                        this.snackbarSave = true
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                            //this.alertError = true
                        //Заменить код
                    })
            },

        },
        mounted() {
            this.getEmployee(this.$route.params.id)
        }
    }
</script>

<style scoped>

</style>
import http from "../http-common"
import authHeader from './auth-header';

class RolesDataService {
    //Запрос всех записей
    find() {
        return http.get(`/roles`,{ headers: authHeader() });
    }

    //Запрос записи по id
    get(id) {
        return http.get(`/roles/${id}`,{ headers: authHeader() });
    }

    //Сохраняем пермишены для указанной роли
    savePermissions(id, permissions) {
        return http.put(`saveRolePermissions/${id}`, permissions, { headers: authHeader() })
    }

    //Запрос сотрудников, привязанных к указанной роли
    getUsers (roleid) {
        return http.get(`/employeesByRole?roleid=${roleid}`,{ headers: authHeader() });
    }

    //Добавление Сотрудника к роли
    addEmployeeToRole (roleid, employeeid) {
        return http.post(`/addEmployeeToRole?roleid=${roleid}&employeeid=${employeeid}`, null, { headers: authHeader() });
    }

    //Удаление Сотрудника из роли
    deleteEmployeeFromRole(roleid, employeeid) {
        return http.delete(`/deleteEmployeeFromRole?roleid=${roleid}&employeeid=${employeeid}`, { headers: authHeader() });
    }


}

export default new RolesDataService()
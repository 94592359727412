<template>
    <div>
        <v-row>
            <v-col>
                <span style="font-size: 25px; font-weight: 300">Роли</span>
            </v-col>
            <v-col class="d-flex justify-end">
                <AddCustomerForm/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                        :headers="headers"
                        :items="items"
                        :items-per-page="50"
                        :footer-props="{
                             itemsPerPageOptions: [ 50, 100, -1],
                             itemsPerPageAllText: 'Все',
                             itemsPerPageText: 'Строк на странице:'
                        }"
                        :loading="loading"
                        loading-text="Загрузка данных... Пожалуйста подождите"
                >
                    <template v-slot:item.name="{ item }">
                        <router-link
                                class="itemlink" :to="{ name: 'EditRole', params: { id: item.id }}">{{ item.name}}
                        </router-link>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AddCustomerForm from "@/components/AddCustomerForm";
    import * as ExternalRules from "@/rules/ExternalRules";
    import RolesDataService from "@/services/RolesDataService";

    export default {
        name: "RolesTable",
        components: {AddCustomerForm},

        data () {
            return {
                searchString: '', //Строка поиска - ФИО/Наименование
                innString: '', //Строка поиска - ИНН
                loading: true,
                externalRules: ExternalRules,
                headers: [
                    {
                        text: 'Наименование',
                        value: 'name',
                        width: 400
                    },
                    {
                        text: 'Описание',
                        value: 'description',
                        sortable: false
                    }
                ],
                items: [],
            }
        },
        mounted () {
            //read Localstorage
            if (localStorage.customerssearchstring) {
                this.searchString = localStorage.customerssearchstring
            }
            if (localStorage.customersinnstring) {
                this.innString = localStorage.customersinnstring
            }

            //Запрашиваем данные с сервера
            this.get()
        },
        filters: {
            formatClientType: function (clienttype) {
                switch (clienttype) {
                    case 'CL' :
                       return 'Клиент'
                    case 'PD' :
                        return 'Подрядчик'
                    case 'KF' :
                        return 'Контактное лицо'
                    case 'OU' :
                        return 'Эксплуатирующая организация'
                    default:
                        return clienttype
                }
            }
        },
        methods: {
            resetForm: function () {
                this.searchString = ''
                this.innString = ''
            },
            get: function () {
                //Save Localstorage
                localStorage.customerssearchstring = this.searchString
                localStorage.customersinnstring = this.innString

                this.loading = true
                RolesDataService.find()
                    .then(response => {
                        this.items = response.data
                        this.loading = false
                        console.log(response.data)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        }
    }
</script>

<style scoped>

</style>